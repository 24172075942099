<div class="background-recompense" *ngIf="isRecompenseSelected"></div>

<!-- Hero Start -->
<section class="bg-profile d-table w-100 bg-primary"
  style="background: url('assets/images/account/bg.png') center center;" id="home">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-lg-2 col-md-3 text-md-start text-center">
                <img [src]="userProfile?.badge ? userProfile?.badge?.image : 'assets/images/profile.jpg' " class="avatar avatar-large rounded-circle shadow d-block mx-auto"
                  alt="">
              </div>

              <div class="col-lg-10 col-md-9">
                <div class="row align-items-end">
                  <div class="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                    <h3 class="title mb-0">{{ userProfile?.name | uppercase }}</h3>
                    <small class="text-muted h6 me-2">{{ userProfile?.title?.name }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Profile Start -->
<section class="section mt-60">
  <div class="container mt-lg-3">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
        <div class="sidebar sticky-bar p-4 rounded shadow">
          <div class="widget">
            <h5 class="widget-title">Nivel :</h5>
            <div class="row mt-4">
              <div class="col-6 text-center">
                <i-feather name="user-plus" class="fea icon-ex-md text-primary mb-1"></i-feather>
                <h5 class="mb-0"> {{ userProfile?.level }}</h5>
              </div>
            </div>
          </div>

          <div class="widget mt-4 pt-2">
            <div class="progress-box mt-4">
              <div class="progress">
                <div class="progress-bar position-relative bg-primary" [style.width]="progressWidth">
                  <div class="progress-value d-block text-muted h6"> {{ userProfile?.exp}} / {{ userProfile?.next_level}}</div>
                </div>
              </div>
              <h6 class="title text-muted">Experiencia</h6>

            </div>
          </div>

          <div class="widget mt-4">
            <ul class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
              <li class="navbar-item account-menu px-0 active">
                <a routerLink="/account-profile" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                  <span class="h4 mb-0"><i class="uil uil-dashboard"></i></span>
                  <h6 class="mb-0 ms-2">Profile</h6>
                </a>
              </li>

            <!--   <li class="navbar-item account-menu px-0 mt-2">
                <a routerLink="#" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                  <span class="h4 mb-0"><i class="uil uil-book-open"></i></span>
                  <h6 class="mb-0 ms-2">Mis Historias</h6>
                </a>
              </li> -->

              <li class="navbar-item account-menu px-0 mt-2">
                <a routerLink="#" (click)="openArchievement(content)" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                  <span class="h4 mb-0"><i class="uil uil-favorite"></i></span>
                  <h6 class="mb-0 ms-2">Insignias</h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-12">
        <div class="border-bottom pb-4" *ngIf="!userProfile?.gender && !showSelectPj">
          <h5>Selecciona tu Personaje base</h5>
          <p class="text-muted mb-0">
            Podrás personalizar tu personaje a medida que ganes recompensas. Estas pueden ser vestimenta, insignias, títulos, incluso mascotas que te podrán acompañar en tus aventuras.
            Tanto tu personaje como mascota podrás utilizarlas en las historias si deseas.
          </p>
          <div class="row ">
            <div class="col-md-6 mt-4 pt-2 pt-sm-0">
              <div class="align-items-center p-3 mt-4" >
                <img (click)="selectCharacters(modalSelectCharacter, 'male')" src="assets/images/book/customization/male/main-character.png" class="select-character character-male" alt="" />
              </div>
            </div> 

            <div class="col-md-6 mt-4 pt-2 pt-sm-0">
              <div class="align-items-center p-3 mt-4">
                <img (click)="selectCharacters(modalSelectCharacter, 'female')" src="assets/images/book/customization/female/main-character.png" class="select-character character-female" alt="" />
              </div>
            </div> 
          </div> 
        </div> 
        <form *ngIf="avatarForm && userProfile.gender" [formGroup]="avatarForm" >
          <div class="border-bottom pb-4">
            <div class="row">
              <div class="col-md-5 mt-4 canvas-character">
                <div class="character-container">
                  <img class="character-parts head" src="{{ avatar.head }}" width="50%">
                  <img class="character-parts body" src="{{ avatar.body }}" width="50%">
                  <!--<img class="character-parts pet" src="assets/images/book/customization/male/pants/{{ avatar.pet }}" width="50%">-->
                </div>
              </div>
              <div class="col-md-2 mt-4 canvas-pet">
                <div class="pet-container">
                  <img src="{{ avatar.pet }}"  class="pet-position">
                </div>
              </div>

              <div class="col-md-5 mt-4 pt-2 pt-sm-0">
                <h5>Perzonalización :</h5>

                <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4">
                  <img src="assets/images/book/customization/icon-head.png" class="avatar avatar-ex-sm" alt="">
                  <div class="flex-1 content ms-3">
                    <select 
                      class="form-select form-control" aria-label="Default select example" id="Sortbylist-job"
                      formControlName="head">
                      <option value="assets/images/book/customization/{{userProfile.gender}}/head/head-v1.png">Peinado Base</option>
                      <option *ngFor="let head of skins.headItems" [value]="head.image"> {{ head.name }}</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4">
                  <img src="assets/images/book/customization/icon-body.jpg" class="avatar avatar-ex-sm" alt="">
                  <div class="flex-1 content ms-3">
                    <select 
                      class="form-select form-control" aria-label="Default select example" id="Sortbylist-job"
                      formControlName="body">
                      <option selected value="assets/images/book/customization/{{userProfile.gender}}/body/body-v1.png">Camiseta Base</option>
                      <option *ngFor="let body of skins.bodyItems" [value]="body.image"> {{ body.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4">
                  <img src="assets/images/book/customization/icon-pet.png"  class="avatar avatar-ex-sm" alt="">
                  <div class="flex-1 content ms-3">
                    <select 
                    class="form-select form-control" aria-label="Default select example" id="Sortbylist-job"
                    formControlName="pet">
                    <option *ngIf="pets.length === 0" value="">No tienes mascotas aún</option>
                    <option *ngIf="pets.length > 0" value="" >Seleccionar</option>
                    <option *ngFor="let pet of pets" [value]="pet.image"> {{ pet.name }}</option>
                  </select>
                  </div>
                </div>

                <br>
                <h5>Titulos :</h5>
                <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4">
                  <img src="assets/images/book/customization/icon-title.png" class="avatar avatar-ex-sm" alt="">
                  <div class="flex-1 content ms-3">
                    <select 
                      class="form-select form-control" aria-label="Default select example" id="Sortbylist-job"
                      formControlName="title">
                      <option *ngIf="titles.length === 0" value="">No tienes titulos aún</option>
                      <option *ngIf="titles.length > 0" value="" >Seleccionar</option>
                      <option *ngFor="let title of titles" [value]="title._id">{{ title.name }}</option>
                    </select>
                  </div>
                </div>

                <br>
                <h5>Insignias :</h5>
                <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4">
                  <img src="assets/images/book/customization/icon-title.png" class="avatar avatar-ex-sm" alt="">
                  <div class="flex-1 content ms-3">
                    <select 
                      class="form-select form-control" aria-label="Default select example" id="Sortbylist-job"
                      formControlName="badge">
                      <option *ngIf="badges.length  === 0" value="">No tienes insignias aún</option>
                      <option *ngIf="badges.length > 0" value="" >Seleccionar</option>
                      <option *ngFor="let badge of badges" [value]="badge._id"> {{ badge.name }}</option>
                    </select>
                  </div>
                </div>
                <br>
              </div>
                
              <button class="btn btn-primary" (click)="saveCharacter()" [disabled]="isSaving">
                <i *ngIf="isSaving" class="spinner-grow" role="status"></i>
                <span *ngIf="!isSaving" class="txt-btn">Guardar</span>
              </button>

            </div>
          </div>

          <h5 class="mt-4 mb-0" *ngIf="listRecompenses.length === 0">No tienes ninguna Recompensa por ahora</h5>
          <h5 class="mt-4 mb-0" *ngIf="listRecompenses.length > 0">Recompensas:</h5>
          <div class="row">
            <div class="col-md-12 mt-4 pt-2">
              <div class="recompense-container">
                <div *ngFor="let recompense of listRecompenses">
                  <img class="gift" src="assets/images/book/treasure.png" (click)="selectedRecompense(recompense)" />
                </div>
              </div>
            </div>    
          </div>
        </form>
      </div>
    </div>
  </div>
</section>


<ng-template #content let-modal>
  <div class="modal-content rounded shadow border-0" [ngStyle]="{'background-image': 'url(assets/images/book/insignias/background.png)'}">
    <div class="modal-header">
      <h5 class="modal-title text-white" id="album-title">Álbum de Insignias</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-4" style="max-height: 70vh; overflow-y: auto;">
      <div class="container">
        <div *ngIf="userProfile.items.badgeItems.length > 0; else noItems" class="badge-container">
          <div class="badge-card" *ngFor="let item of userProfile.items.badgeItems">
            <img [src]="item.image" [alt]="item.name">
            <div class="badge-info">
              <h5>{{ item.name }}</h5>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
        <ng-template #noItems>
          <div class="d-flex justify-content-center align-items-center" style="height: 200px;">
            <p style="color:white;">No posees insignias archivadas, sube niveles y coleccionalas todas.</p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalSelectCharacter let-c="close" let-d="dismiss">
    <div class="modal-body p-4">
      <div class="container modal-characters text-muted">
        <p>¿Estás seguro de elegir este personaje?</p>
        <button  type="button" class="btn btn-danger close" (click)="d('Cross click')">Cancelar</button>
        <button  type="button" class="btn btn-primary" (click)="selectedCharacterSave()">Aceptar</button>
      </div>
    </div>
</ng-template>

<div class="floating-image-container" (click)="saveRecompense()" *ngIf="isRecompenseSelected">
  <img 
      class="recompense-image" 
      [src]="recompense.type === 'title' ? 'assets/images/book/parchment.png' : recompense.image" 
      [title]="recompense.type === 'title' ? 'Has ganado un nuevo título' : recompense.type === 'decoration' ? '¡Has ganado una nueva insignia!' : ''"
      [ngClass]="{'round-image': recompense.type === 'decoration'}"/>
  <img [src]="currentImage" alt="Imagen flotante" class="aura-effect">
  <span class="recompense-text" *ngIf="imageChanged">Felicidades has obtenido: {{ recompense.name }}</span>
  <button  class="close-button"  (click)="closeRecompense()" title="Cerrar">X</button>
</div>



<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
