import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
export class TransversalVariables {
    credit: number = 0;

    constructor(){}

    setCredits(credit){
      this.credit = credit
    }
    getCredits(){
      return this.credit
    }
}