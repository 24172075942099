<!-- Hero Start -->
<section class="home-slider position-relative" id="home">
  <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <owl-carousel-o id="customer-testi" [options]="customOptions">
        <ng-template carouselSlide>
          <div class="slide-inner slide-bg-image d-flex align-items-center vh-100" style="background: center center;"
          style="background-image:url('assets/images/portada-page.png'); background-repeat: no-repeat;  background-size: cover; background-position-y: 52%;">
          <div class="bg-overlay"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <div class="title-heading text-white mt-4">
                  <h1 class="display-4 title-dark fw-bold mb-3">¡Comienza esta fantástica aventura!</h1>
                  <p class="para-desc para-dark mx-auto text-light">¡Despierta tu imaginación y crea historias educativas y asombrosas,
                    el momento de crear tu propio legado literario es ahora! </p>
                  <div class="mt-4">
                    <a   (click)="login()" class="btn btn-primary mt-2"><i class="uil uil-book-open"></i> ¡Comencemos!</a>
                   <!--<a [ngxScrollTo]="'#courses'" class="btn btn-primary mt-2"><i class="uil uil-book-open"></i> ¡Comenzemos!</a>-->
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </div><!-- end slide-inner -->
          <!--end slide-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="slide-inner slide-bg-image d-flex align-items-center vh-100" style="background: center center;"
        style="background-image:url('assets/images/course/bg02.jpg')">
        <div class="bg-overlay"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="title-heading text-white mt-4">
                <h1 class="display-4 title-dark fw-bold mb-3">Better Education For A Better Future </h1>
                <p class="para-desc text-white-50 mx-auto">Launch your campaign and benefit from our expertise on
                  designing and managing conversion centered bootstrap v5 html page.</p>
                <div class="mt-4">
                  <a [ngxScrollTo]="'#admission'" class="btn btn-primary mt-2"><i class="uil uil-graduation-cap"></i>
                    Admission Now</a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </div><!-- end slide-inner -->
          <!--end slide-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="slide-inner slide-bg-image d-flex align-items-center vh-100" style="background: center center;"
        style="background-image:url('assets/images/course/bg03.jpg')">
        <div class="bg-overlay"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="title-heading text-white mt-4">
                <h1 class="display-4 title-dark fw-bold mb-3">Education Is The Key of Success</h1>
                <p class="para-desc para-dark mx-auto text-light">Launch your campaign and benefit from our expertise on
                  designing and managing conversion centered bootstrap v5 html page.</p>
                <div class="mt-4">
                  <a [ngxScrollTo]="'#instructors'" class="btn btn-primary mt-2"><i class="uil uil-user"></i>
                    Instructors</a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </div><!-- end slide-inner -->
          <!--end slide-->
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="features-absolute">
          <div class="row">
            <div class="col-md-4">
              <div class="card border-0 text-center features feature-clean feature-primary course-feature p-4 overflow-hidden shadow">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-file d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 mt-4">
                  <a href="javascript:void(0)" class="title h5 text-dark"> Comprensión de la Historia</a>
                  <p class="text-muted mt-2">Evaluamos la comprensión al leer una historia creada, a través de preguntas y ejercicios interactivos. </p>
                  <a href="javascript:void(0)" class="text-primary read-more">Leer más <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                  <i class="uil uil-file text-primary full-img"></i>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div class="card border-0 text-center features feature-clean feature-primary course-feature p-4 overflow-hidden shadow">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-graduation-cap d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 mt-4">
                  <a href="javascript:void(0)" class="title h5 text-dark"> Identificación de Temas y Conceptos</a>
                  <p class="text-muted mt-2">La plataforma está diseñada para ayudar a los estudiantes a identificar y analizar los temas principales,
                    conceptos y moralejas presentes en la historia.. </p>
                  <a href="javascript:void(0)" class="text-primary read-more">Leer más <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                  <i class="uil uil-graduation-cap text-primary full-img"></i>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div class="card border-0 text-center features feature-clean feature-primary course-feature p-4 overflow-hidden shadow">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-book-reader d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 mt-4">
                  <a href="javascript:void(0)" class="title h5 text-dark"> Feedback Personalizado y Adaptativo</a>
                  <p class="text-muted mt-2">Basado en las respuestas y el rendimiento del estudiante, el sistema proporciona feedback personalizado
                    que se adapta a su nivel de comprensión y áreas de mejora. </p>
                  <a href="javascript:void(0)" class="text-primary read-more">Leer más <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                  <i class="uil uil-book-reader text-primary full-img"></i>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- FEATURES END -->

<!-- About Start -->
<section class="section pt-0">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12">
        <img src="assets/images/index-header-2-min.jpg" class="img-fluid shadow rounded" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">¿Cómo Funciona <span class="text-primary">TaleWeaver?</span></h4>
          <p class="text-muted"><span class="text-primary fw-bold">TaleWeaver</span>
            es una innovadora plataforma de creación de historias impulsada por inteligencia artificial. Diseñada para estimular
            la imaginación y la creatividad, TaleWeaver permite a los usuarios generar historias personalizadas de manera sencilla y rápida.
            Solo necesitas ingresar un contexto, añadir personajes y definir el escenario. Además, 
            puedes personalizar los personajes utilizando tus propios rasgos faciales a través de una foto. Con un solo clic, TaleWeaver 
            crea una narrativa única y fascinante, ideal para la educación, el entretenimiento y el desarrollo de habilidades de lectura y escritura.
          </p>
          <p class="text-muted">Estos son los pasos a seguir:.</p>
          <ul class="list-unstyled mb-0 text-muted">
            <li><i class="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2"></i>Ingresa el Contexto</li>
            <li><i class="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2"></i>Añade los Personajes</li>
            <li><i class="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2"></i>Define el Escenario</li>
            <li><i class="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2"></i>Personaliza tu Personaje ( solo si lo deseas )</li>
            <li><i class="uil uil-arrow-circle-right text-primary h5 mb-0 align-middle me-2"></i>Genera tu Historia</li>
          </ul>
          <br>
          <p><span class="text-primary fw-bold">¡Así de fácil!</span></p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--enc container-->
</section>
<!--end section-->
<!-- About End -->

<!-- Cta Start -->
<section  class="section bg-cta" style="background: url('assets/images/course/bg02.jpg') center center;" id="cta" style="display: none;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">We Bring New Online Courses</h4>
          <p class="text-white-50 para-desc mx-auto">Start working with Landrick that can provide everything you
            need to generate awareness, drive traffic, connect.</p>
          <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
            class="play-btn mt-4 video-play-icon">
            <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
          </a>
          <ng-template #content let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/12022651" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Cta End -->

<!-- FEATURED STUDENTS -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Alumnos Destacados</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Conoce a los alumnos destacados seleccionados por <span class="text-primary fw-bold">TaleWeaver</span>.
            Descubre a los estudiantes que han sobresalido por sus altas puntuaciones y evaluaciones en las historias de TaleWeaver,
            reflejando su esfuerzo, talento y dedicación.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
  </div>
    <!--end row-->

  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-9 col-md-8">
        <div class="section-title">
          <h6 class="mb-0">Galería de Excelencia</h6>
        </div>
      </div><!--end col-->

      <!--
      <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="form custom-form">
          <div class="mb-0">
            <select class="form-select form-control" aria-label="Default select example" id="Sortbylist-job">
              <option selected>Default</option>
              <option>Newest</option>
              <option>Oldest</option>
              <option>Random</option>
            </select>
          </div>
        </div>
      </div>
      -->
    </div><!--end row-->

    <div class="row">

      <div *ngFor="let student of featuredStudents" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="job-box job-primary candidate-list card rounded border-0 shadow">
          <div class="card-body">
            <ul class="list-unstyled align-items-center">
              <li class="list-inline-item float-end">
                Nivel. {{ student.level }}
              </li>
              <li class="list-inline-item">
                <span class="badge rounded-pill bg-soft-success">Featured</span>
              </li>
            </ul>

            <div class="content text-center">
              
              <img [src]=" student.badge ? student.badge : 'assets/images/profile.jpg'" class="avatar avatar-md-md shadow-md rounded-circle" alt="">
              <ul class="list-unstyled mb-1 mt-2">
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
              </ul>
              <a routerLink="/job-candidate" class="text-dark h5 name">{{ student.name | uppercase }}</a>
              <p class="text-muted my-1">{{ student.title?.name }}</p>

              <span class="text-muted">
                <i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>
                Puntuación: <span class="text-success">{{ student.exp }}</span>
              </span>
              <br> 
              <br> 

             <!--  <div class="d-grid btn-leer-mas">
                <a routerLink="/job-candidate" class="btn btn-soft-primary">
                  Leer más <i class="uil uil-angle-right-b align-middle"></i>
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div><!--end col-->

    </div><!--end row-->
  </div><!--end container-->
</section><!--end section-->
<!--end section-->

<!-- Cta Start -->
<section class="section bg-cta" style="background: url('assets/images/course/bg03.jpg') center center;" id="admission">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-7 col-12">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">Retrospectiva General</h4>
          <p class="para-desc para-dark mb-0 text-white-50">Resumen de los logros y avances alcanzados hasta la actualidad.</p>
        </div>
        <div class="row" id="counter">
          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box">
              <i class="uil uil-graduation-cap text-white-50 h2"></i>
              <h2 class="mb-0 text-white title-dark mt-2"><span class="counter-value" [CountTo]="30" [from]="3"
                  [duration]="4"></span>
              </h2>
              <h6 class="counter-head text-white-50">Estudiantes</h6>
            </div>
            <!--end counter box-->
          </div>
          <!--end col-->

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box">
              <i class="uil uil-book-open text-white-50 h2"></i>
              <h2 class="mb-0 text-white title-dark mt-2"><span class="counter-value" [CountTo]="100" [from]="5"
                  [duration]="4">1</span></h2>
              <h6 class="counter-head text-white-50">Historias</h6>
            </div>
            <!--end counter box-->
          </div>
          <!--end col-->

          <div class="col-md-3 col-6 mt-4 pt-2" style="display:none">
            <div class="counter-box">
              <i class="uil uil-user text-white-50 h2"></i>
              <h2 class="mb-0 text-white title-dark mt-2"><span class="counter-value" [CountTo]="55" [from]="0"
                  [duration]="4"></span>+</h2>
              <h6 class="counter-head text-white-50">Instructors</h6>
            </div>
            <!--end counter box-->
          </div>
          <!--end col-->

          <div class="col-md-3 col-6 mt-4 pt-2" style="display:none">
            <div class="counter-box">
              <i class="uil uil-english-to-chinese text-white-50 h2"></i>
              <h2 class="mb-0 text-white title-dark mt-2"><span class="counter-value" [CountTo]="25" [from]="3"
                  [duration]="4"></span>+</h2>
              <h6 class="counter-head text-white-50">Languages</h6>
            </div>
            <!--end counter box-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Cta End -->

<!-- Testi Subscribe Start -->
<section class="section" style="display:none">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">What Students Say ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Sign up for our Newsletter</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center mt-4 pt-2">
      <div class="col-lg-7 col-md-10">
        <div class="text-center subcribe-form">
          <form>
            <input name="email" id="email" type="email" class="form-control rounded-pill shadow"
              placeholder="Your email :" required aria-describedby="newssubscribebtn">
            <button type="submit" class="btn btn-pills btn-primary" id="newssubscribebtn">Subscribe</button>
          </form>
          <!--end form-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi Subscribe End -->

<!-- Partners Start -->
<section class="py-4 bg-light" style="display:none">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->