
<app-notification-recompense [rewardReceived]="rewardReceived"></app-notification-recompense>

<div class="background-book"></div>
<div [@openBook]="animationState"  class="flipbook centered" #flipbook *ngIf="showBook">
    <div  (click)="closeBook()" class="close-btn" #divImgClose>
        <img src="assets/images/book/close.png" #imgClose />
    </div>
    <div class="leaf" #leaf>
        <div class="page front title external book-tap">
            <div>
                <h1>{{book.title}}</h1>
            </div>
        </div>
        <div class="page back book-back-tap">
            <div class="pageNumber"></div>
        </div>
    </div>
    <div class="leaf" #leaf *ngFor="let item of book.pages" >
        <div class="page front old-paper"  >
            <div class="pageNumber">{{item.leaft.front.page}}</div>
            <p class="contentLeaf" [innerHTML]="item.leaft.front.text"></p>
                
            <div class="page back old-paper" >
                <div class="pageNumber">{{item.leaft.back.page}}</div>
                <p class="contentLeaf" [innerHTML]="item.leaft.back.text"></p>
            </div>
        </div>
    </div>
    <div class="leaf" #leaf >
        <div class="page front book-back-tap" >
            <div class="pageNumber"></div>
                
            <div class="page back book-end-tap">
                <div class="pageNumber"></div>
                <p class="contentLeaf"></p>
            </div>
        </div>
    </div>
    <div class="controls" #controls>
        <img (click)="turnPage(-1, 'leftmove')" class="prevPage" src="assets/images/book/prev.png" #prevPage/>
        <img (click)="turnPage(1, 'rightmove')" class="nextPage" src="assets/images/book/next.png" #nextPage/>
    </div>
</div>

<!--<div class="controls centered" *ngIf="showBook" >
    <div class="left"></div>
    <div class="center">
        <div class="cont-arrow-left">
            <img (click)="turnPage(-1, 'leftmove')" class="prevPage" src="assets/images/book/prev.png" #prevPage/>
        </div>
        
        <div class="cont-arrow-right">
        </div>
    </div>
    <div class="right"></div>
</div>-->

<app-options class="centered"
    [evaluationResults]="evaluationResults"
    [isEvaluationDone]="isEvaluationDone"
    [storyText]="storyText"
    (createTestEmmit)="createTest()"
    (finishEvaluationEmmit)="closeBook()"></app-options>

<app-evaluation
    class="app-evaluation"
    *ngIf="showEvaluation"
    [questionsEvaluation]="questionsEvaluation"
    [storyText]="storyText"
    (evaluationDoneEmmit)="evaluationDone($event)"></app-evaluation>