import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaypalService {

  constructor(
    private http: HttpClient) { }
  
  setPayment(body) {
    const url = `${environment.api}${environment.module_context.paypal}/invoice`
    return this.http.post(url, body).pipe(map((x: any) => x));
  }

}
