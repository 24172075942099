<!--<div class="modalNotification">
    <div class="modal-content">
        <span class="close-button" onclick="closeModal()">&times;</span>
        <h2>¡Felicidades!</h2>
        <p>Has ganado un premio por tus logros en Taleweaver.</p>
        <button class="accept-button" onclick="closeModal()">Aceptar</button>
    </div>
</div>-->

<div class="modal-overlay" *ngIf="isActive">
    <div class="background-lights"></div> <!-- Luces de fondo -->
    <div class="modal-content">
      <h2>Felicidades!</h2>
      <div class="treasure-icon">
        <img src="assets/images/book/treasure.png" alt="Prize">
      </div>
      <p class="message">{{ message }}</p>
      <span class="last-text">Recuerda que puedes encontrar tus recompensas en tu perfil.</span>
      <button (click)="closeModal()">Aceptar</button>
    </div>
  </div>