import {
  AfterViewInit, Component, ElementRef,
  Input, OnInit, Output, QueryList,
  ViewChild, ViewChildren, EventEmitter, 
  OnDestroy} from '@angular/core';
import { bookInterface, pagesInterface } from './bookInterface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { StoryService } from 'src/app/shared/services/story.service';
import { Subscription } from 'rxjs';
import { WindowSizeService } from './window-size.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.css'],
  animations: [
    trigger('openBook', [
        state('initial', style({
          transform: 'translateX(0)'
        })),
        state('moveRight', style({
          transform: 'translateX(20%)'
        })),
        state('moveLeft', style({
          transform: 'translateX(52%)'
        })),
        transition('initial => moveRight', animate('3s ease-in-out')),
        transition('moveRight => moveLeft', animate('3s ease-in-out'))
    ])
  ]
})
export class BookComponent implements OnInit, AfterViewInit, OnDestroy{
  @Input('storyText') storyText: any;
  @Output() activeBookEmmit = new EventEmitter<boolean>;
  @ViewChildren('leaf') leaftsReaf: QueryList<ElementRef>;
  @ViewChild('flipbook')  flipbookRef: ElementRef;
  @ViewChild('divImgClose')  divImgClose: ElementRef;
  @ViewChild('imgClose')  imgClose: ElementRef;
  @ViewChild('controls') controlsRef: ElementRef;

  private windowSizeSubscription: Subscription;
  windowWidth: number;
  windowHeight: number;
  options:any  = {}
  currentPagePosition: number = 0;
  transform: string =''
  title_old = ''
  animationState: string = 'start';
  book: bookInterface = {title: '', pages: []};
  bookPages: pagesInterface[] = [];
  limitWords = 320
  showBook: boolean = true
  showEvaluation: boolean = false
  questionsEvaluation: Array<any> = []
  answersEvaluation: Array<any> = []
  isEvaluationDone: boolean = false
  evaluationResults: {} = {}
  pages = [];
  rewardReceived: any

  constructor(
    private _storyService: StoryService,
    private windowSizeService: WindowSizeService
  ){
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    if ( window.innerWidth > 1800){
      this.limitWords = 320
      this.openBook()
    }

    if ( window.innerWidth > 1500 &&  window.innerWidth <= 1800){
      this.limitWords = 250
      this.openBook()
    }

    if ( window.innerWidth > 1300 &&  window.innerWidth <= 1500){
      this.limitWords = 200
      this.openBook()
    }
    
    if ( window.innerWidth > 1000 &&  window.innerWidth <= 1300){
      this.limitWords = 120
      this.openBook()
    }

  }

  ngAfterViewInit(): void {
    this.turnPage(0, 'initial');
  }

  ngOnDestroy() {
    if (this.windowSizeSubscription) {
      this.windowSizeSubscription.unsubscribe();
    }
  }

  setPagePosition(page, position, index){
    this.transform = "translate3d(0,0,"+((position<0?1:-1)*Math.abs(index))+"px)"
    if(position<0){
        this.transform+="rotate3d(0,1,0,-180deg)";
        page.nativeElement.classList.add("turned")
    }else{
        page.nativeElement.classList.remove("turned")
    }

    if(page.nativeElement.style.transform != this.transform){
        page.nativeElement.style.transform= this.transform;
    }
  }

  turnPage(delta, state_animation){

    if(state_animation === 'rightmove' && this.currentPagePosition == 0){
        this.animationState = 'moveRight';
     }
    if (state_animation === 'rightmove' &&  this.currentPagePosition == (this.leaftsReaf.length-1)){
        this.divImgClose.nativeElement.style.left = '30%'
        this.imgClose.nativeElement.style.width = '15%'
      this.controlsRef.nativeElement.style.margin = '1100px 80% 0px 0px'

        this.animationState = 'moveLeft';
    }else{
      this.divImgClose.nativeElement.style.left = '82%'
      this.imgClose.nativeElement.style.width = '55%'
      this.controlsRef.nativeElement.style.margin = '1100px 0px 0px 0px'
    }

    if (state_animation === 'leftmove' &&  this.currentPagePosition == 1){
      this.animationState = 'initial';
    }

    if (state_animation === 'leftmove' &&  this.currentPagePosition ==  (this.leaftsReaf.length)){
     
      this.animationState = 'moveRight';
    }

    this.currentPagePosition+=delta;
    if(this.currentPagePosition < 0){
        this.currentPagePosition = 0;
        return;
    }
    if(this.currentPagePosition > this.leaftsReaf.length){
        this.currentPagePosition = this.leaftsReaf.length;
        return;
    }
    this.leaftsReaf.forEach((page, index)=>{
        var pageNumber = index;
        this.setPagePosition(page,pageNumber-this.currentPagePosition,index)
    });
  }

  openBook(){
    console.log(this.storyText)
    this.rewardReceived = this.storyText.recompense
    const regex = /(<img src="[^"]+" \/>)|(<span style="[^"]+">[^<]+<\/span>)/g;
    let pages = this.storyText.content.split(regex);

    pages = pages.filter(
      p => p != undefined &&
      p !== " <br><br> " &&
      p !== ' </div> <br><br> '); // Eliminamos cualquier parte vacía

    this.pages = []
    for(let i = 0; i < pages.length; i++){
      this.createPages(pages[i])
    }

    this.buildTextInPages()

    this.book.title = this.storyText.title
    this.book.pages = this.bookPages
     

  }

  createPages(text: string){
    // Divide el texto en palabras
    const cleanText = text.trim().replace(/<\/?div>/g, '').replace(/<br\s*\/?>/g, '');
    const words = cleanText.split(/\s+/);
    const totalWords = words.length;

    // Utiliza un bucle para dividir las palabras en segmentos
    for (let j = 0; j < totalWords; j += this.limitWords) {
      // Extrae un segmento de palabras del tamaño definido
      const chunk = words.slice(j, j + this.limitWords).join(' ');
      // Añade el segmento al array
      this.pages.push(chunk);
    }

    return true
  }

  buildTextInPages(){
    let frontText = ''
    let backText = ''
    let isCompleteFirstPage = false
    let review = false
    let isImg= false
    let frontImage = false
    let nPage = 1

    for(let [index, page] of this.pages.entries()){
      if (page.includes("<img")){
        isImg = true
      }
      if (page.includes("<span")) {
        review = true
      }

      if (frontText === '' || frontImage){
        if (isImg){
          if (review){
            frontText += `<br> ${page}`
            frontImage = false
            isImg = false
            review = false
          }else{
            frontImage = true
            frontText = page
          }
        }else{
          frontText = page
        }
        
      }else{
        if (isImg){
          if(review){
            backText += `<br> ${page}`
            isCompleteFirstPage = true
          }else{
            backText = page
          }
        }else{
          backText = page
          isCompleteFirstPage = true
        }
      }

      if (index == this.pages.length-1){
        isCompleteFirstPage = true
      }

      if (isCompleteFirstPage){
        this.bookPages.push(
          {
            leaft:{
              front: { text: frontText, page: nPage},
              back: { text: backText , page: nPage+1}
            }
          })
        isCompleteFirstPage = false
        isImg = false
        review = false
        frontText =''
        backText = ''
        nPage += 2
      }
    }
  }
  

  closeBook(){
    this.activeBookEmmit.emit(false)
  }

  createTest(){
    let sendData = {
      story: this.storyText.content
    }
    this._storyService.createTest(sendData).subscribe(test =>{
      this.questionsEvaluation = test.data
      this.showBook = false
      this.showEvaluation = true
    })
  }

  evaluationDone(evaluationResults){
    this.evaluationResults = evaluationResults
    this.isEvaluationDone = true
  }

}
