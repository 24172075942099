<div class="col-12 mt-4 pt-2">

    <div class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative" :class="{{character.type == 'MAIN' ? 'character-bg-primary' : 'character-bg-secondary'}}" >
        <img src="{{character.type == 'MAIN' ? 'assets/images/icon/star.svg' : 'assets/images/icon/user.svg'  }}" class="avatar avatar-md-sm" alt="">

        <div class="ms-md-4 mt-4 mt-sm-0">
            

            <ng-container *ngIf="characterEdit === true"> 
                <div class="col-lg-12 col-md-12">
                    <div class="input_name mb-0">
                        <input  (change)="editedCharacterChange($e)" #nameInput type="text" class="form-control" on-focusout="focusOutFunction()" placeholder="Ingrese Nombre" [(ngModel)]="character.name">
                    </div>
                    <div class="camera_icon mb-0">
                        <i  class="uil align-middle uil-camera camera_icon" (click)="takePhoto()" *ngIf="character.type === 'MAIN'"></i>
                        <i  class="uil align-middle camera_icon" *ngIf="character.type === 'SECONDARY'"></i>
                    </div>
                    <p class="text-muted mb-0 mt-1">Representa el nombre del personaje.</p>
                </div>
            </ng-container>             

            <ng-container *ngIf="characterEdit === false"> 
                <a href="javascript:void(0)" (click)="editButtonClick()" class="title text-dark h5" >{{character.name}} </a>
            </ng-container> 

            <div class="mt-2 d-flex gap-1">
                <span class="badge" :class="{{character.type === 'MAIN' ? 'bg-soft' : 'bg-soft-warning'}}" >{{character.type === 'MAIN' ? 'Personaje Principal' : 'Personaje Secundario'}}</span>
            </div>
        </div>

        <div>
            <video #videoElement width="640" height="480" autoplay style="display: none;"></video>
            <canvas #canvasElement width="150" height="112"></canvas>
        </div>
        <div class="position-absolute top-0 end-0 mt-3 me-3">
            <a href="javascript:void(0)" (click)="deleteButtonClick()" class="btn btn-icon btn-pills btn-soft">
                <i class="uil align-middle uil-trash"> </i>
            </a>
        </div>
    </div>



</div>