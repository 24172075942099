<div class="container">

    <div *ngIf="!canGenerateStory"> 
      No hay créditos 
      <a routerLink="/pricing">Carga créditos!</a>
    </div>
  
    <div *ngIf="canGenerateStory" class="container mt-5 mt-sm-0">
        <div class="row">
            <div class="col-lg-8 col-md-6 col-12">

                <div class="card rounded p-4 mt-4 shadow history-main-data-bg">
                  <div class="row text-start">
                      <div class="col-lg-12 col-md-12">
                          <div class="mb-0">
                              <input type="text" class="form-control" [(ngModel)]="title" required placeholder="Titulo de la historia" maxlength="30">
                          </div>
                          <p class="text-muted mb-0 mt-1">Representa el título con el que podrás encontrar tu historia despúes de generarla, no tiene efectos en la generación de esta.</p>
                      </div>
                      <div class="col-lg-12 col-md-12 mt-4">
                        <div class="mb-0">
                            <textarea type="text" class="form-control" [(ngModel)]="context" required placeholder="Contexto"  maxlength="400"></textarea>
                        </div>
                        <p class="text-muted mb-0 mt-1">Representa el contexto donde se desarrollará la historia.</p>
                      </div>
                      <div class="col-lg-12 col-md-12 mt-4">
                        <div class="mb-0">
                            <input type="text" class="form-control" [(ngModel)]="location" required placeholder="Ubicación" maxlength="40">
                        </div>
                        <p class="text-muted mb-0 mt-1">Representa el lugar donde se desarrollará la historia.</p>
                      </div>
                                              
                  </div><!--end row-->

                </div>

                <div class="row align-items-center">

                  <ng-container *ngFor="let character of characters; trackBy: trackByFn">
                    <character 
                      [character]="character"
                      [edit]="true"
                      (editedCharacter)="onCharacterEdit($event)"
                      (deletedCharacter)="onCharacterDelete($event)" >
                    </character>
                  </ng-container>    

                  <div class="col-12 mt-4 pt-2">
                      <div class="job-box job-primary d-md-flex align-items-center border-0 shadow rounded p-4 position-relative" >
                          <img src="assets/images/icon/chatbot.png" class="avatar avatar-md-sm" alt="">
                  
                          <div class="ms-md-4 mt-4 mt-sm-0">
                
                  
                              <ul class="list-unstyled mb-0 mt-2" *ngIf="currentSize<6">
                                  <li class="d-lg-inline text-muted h6 mb-0">
                                    <a *ngIf="currentSize==0" href="javascript:void(0)" (click)="addCharacterButtonClicked()">
                                      <i class="uil uil-plus icons"></i> Agregar Personaje Principal
                                    </a>       
                                    <a *ngIf="currentSize>0" href="javascript:void(0)" (click)="addCharacterButtonClicked()" >
                                      <i class="uil uil-plus icons"></i> Agregar Personaje Secundario
                                    </a>                                                                                
                                  </li>
                              </ul>

                          </div>
                  
                      </div>
                  </div>


                </div>

            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="sidebar p-4 rounded shadow">
                <div class="card pricing pricing-primary business-rate starter-plan text-center shadow border-0 rounded">
                  <div class="card-body">
                    <h5 class="title text-primary mb-4">{{historyType==='basic' ? 'Historia básica.' : 'Historia avanzada.'}} </h5>
                    <h2 class="fw-bold">{{historyType==='basic' ? '1' : '3'}} Crédito(s)</h2>
      
                    <span class="text-muted mb-0">
                      <ul>
                        <li>
                          Un personaje principal
                        </li>
                        <li>
                          Hasta X personajes secundarios.
                        </li>                          
                      </ul>            
                    </span>
                    <div class="d-grid">
                      <button class="btn btn-primary" [disabled]="characters.length < 2 || !location || !title || !context || !validNamesCharacters" (click)="generateStory(content)">Crear historia</button>
                    </div>
                  </div>
              </div>
              </div>
          </div>

        </div>



        <ng-template #content let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{ story_title }}</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label >Capitulo 1</label>
                <div class="input-group" [innerHTML]="story_text"></div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Cerrar</button>
            <button type="button" class="btn btn-primary" disabled>Proximamente Continuar Historia</button>
          </div>
        </ng-template>

        <ng-template #notEnoughtCredits let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"> Aviso </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label >Limite de personajes alcanzado</label>
                <div class="input-group"> Proximamente se habilitará la opción de añadir mas personajes</div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Cerrar</button>
          </div>
        </ng-template>

        <ng-template #errorStory let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"> Error </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label >Ha ocurrido un error inesperado</label>
                <div class="input-group"> Se ha producido un error en la creación de la historia, favor intente nuevamente.</div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Cerrar</button>
          </div>
        </ng-template>

    </div>


  </div>