import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { StoryService } from 'src/app/shared/services/story.service';
import { UserService } from 'src/app/shared/services/user.service';
import { TransversalVariables } from 'src/app/shared/transversalVariables';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.css'],
})
export class CreditsComponent implements OnInit {
  @Input() totalCredits: number = 0;
  public userData: any;
  public isLogged: boolean = false;

  constructor(
    private userService: UserService,
    private _storyService: StoryService,
    private _auth: AuthService,
    public _transversarVairables: TransversalVariables
  ){ }

  ngOnInit(): void {
    this._auth.check().subscribe((status: any) =>{
      if(status){
        this.userData = this.userService.getUserData()
        this.isLogged = true
        this._transversarVairables.setCredits(this.totalCredits)
      }
    })
  }

  reloadCredits(){
    //this.getCredits()
  }
  
}
