<div class="sidebar" [ngClass]="{ 'active': isSidebarOpen }">
    <h2 class="sidebar-title">Hitos</h2>
    <ul class="mission-list">
      <li *ngFor="let mission of missions" class="mission-item">
        <a href="#">
          <span class="mission-name">{{ mission.name }}</span>
          <span class="mission-author">por {{ mission.author }}</span>
        </a>
      </li>
    </ul>
  </div>
  <div id="main" [ngStyle]="{'margin-left': isSidebarOpen ? '250px' : '0'}">
    <button class="openbtn" (click)="toggleSidebar()">&#9776; </button>
  </div>