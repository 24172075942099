<div class="main">
    <div class="container_builder">
        <div class="signup-content">
            <div class="d-xs-none signup-desc">
                <div class="signup-desc-content" >
                    <h2 style="cursor: pointer" (click)="goTo('home')"><span>Tale</span>Weaver</h2>
                    <p class="title">Prueba crear tu propia historia siguiendo todos los pasos.</p>
                    <p class="desc">
                        Comienza ingresando el titulo de tu Libro!
                    </p>
                    <div class="container-img">
                      <img src="assets/images/read-book.png"  width="350" alt="" class="signup-img">
                    </div>
                </div>
            </div>
            <div class="signup-form-conent">
              <div *ngIf="!canGenerateStory"> 
                No hay créditos 
                <a routerLink="/pricing">Carga créditos!</a>
              </div>
              <div *ngIf="canGenerateStory">
                <ng-stepper #cdkStepper [linear]="true">
                  <cdk-step [stepControl]="pricingFormGroup" [optional]="false">
                    <form class="form-steeper-content" [formGroup]="pricingFormGroup"  #formRef="ngForm">
                    <ng-template cdkStepLabel>
                      <div class="step-bullet">1</div>
                      <div class="step-title steeper-xs-title">Plan</div>
                    </ng-template>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                            <div class="form-icon position-relative">
                              <select #pricing class="form-select form-control"  placeholder="Seleccionar...." id="pricing">
                                <option *ngFor="let pricing of pricings" [value]="pricing.id">{{pricing.name}}</option>
                                </select>
                            </div>
                            <span class="error" *ngIf="pricingFormGroup.controls['pricing'].hasError('required')">Plan es requerido
                            </span>
                        </div>
                      </div>
                    </div>
                      <div class="content-buttons">
                        <button 
                          (click)="setPricing(pricing)"
                          class="btn btn-primary mt-2 me-2 btn-steeper"
                          cdkStepperNext>
                          Siguiente
                        </button>
                      </div>
                    </form>
                  </cdk-step>

                  <cdk-step [stepControl]="firstFormGroup" [optional]="false">
                    <form class="form-steeper-content" [formGroup]="firstFormGroup" #formRef="ngForm">
                    <ng-template cdkStepLabel>
                      <div class="step-bullet">2</div>
                      <div class="step-title steeper-xs-title">Nombre Historia</div>
                    </ng-template>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                            <div class="form-icon position-relative">
                                <input 
                                  #title_book
                                  name="name" id="name" type="text" class="form-control-story transparent" placeholder="Título :" formControlName="title_book">
                                <span class="error" *ngIf="firstFormGroup.controls['title_book'].hasError('required') && formRef.submitted">First Name is a required field
                                </span>
                            </div>
                        </div>
                      </div>
                    </div>
                      <div class="content-buttons">
                        <button 
                          class="btn btn-primary mt-2 me-2 btn-steeper"
                          cdkStepperNext>
                          Siguiente
                        </button>
                      </div>
                    </form>
                  </cdk-step>

                  <cdk-step [stepControl]="secondFormGroup" [optional]="false">
                    <form class="form-steeper-content" [formGroup]="secondFormGroup"  #formRef="ngForm">
                    <ng-template cdkStepLabel>
                      <div class="step-bullet">3</div>
                      <div class="step-title steeper-xs-title">Personajes</div>
                    </ng-template>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                            <div class="form-icon position-relative">
                                <input 
                                  #principal_character
                                  name="name" id="name" type="text" class="form-control-story transparent" placeholder="Personaje Principal :" formControlName="principal_character">
                                <span class="error" *ngIf="secondFormGroup.controls['principal_character'].hasError('required')">Personaje principal es requerido
                                </span>
                            </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <table class="table table-bordered" formArrayName="secondary_characters">  
                          <tr class="content-buttons">  
                            <th colspan="2" class="title">Personajes Secundarios:</th>  
                            <th width="150px"><button  (click)="addSecondaryCharacter()" class="btn btn-primary new-btn">Agregar</button></th>  
                          </tr>  
                          <tr *ngFor="let item of secondary_characters().controls; let i=index" [formGroupName]="i">  
                            <td style="width:100%">  
                                <input  
                                  #secondary_characters 
                                  name="name" id="name" type="text" class="form-control-story transparent"
                                  placeholder="{{ i + 1 }}.- Nombre :"
                                  formControlName="name">
                                <span class="error" *ngIf="secondFormGroup.controls['secondary_characters'].hasError('required')">Personajes secundarios es requerido
                                </span>
                            </td>  
                            <td>  
                                <button (click)="removeSecondaryCharacter(i)" class="btn btn-danger new-btn-danger">Eliminar</button>  
                            </td>  
                          </tr>  
                        </table>  
                        
                      </div>
                    </div>
                      <div class="content-buttons">
                        <button 
                          class="btn btn-primary mt-2 me-2 btn-steeper"
                          cdkStepperNext>
                          Siguiente
                      </button>
                      </div>
                    </form>
                  </cdk-step>

                  <cdk-step [stepControl]="thirdFormGroup" [optional]="false">
                    <form class="form-steeper-content" [formGroup]="thirdFormGroup"  #formRef="ngForm">
                    <ng-template cdkStepLabel>
                      <div class="step-bullet">4</div>
                      <div class="step-title steeper-xs-title">Lugar y Contexto</div>
                    </ng-template>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                            <div class="form-icon position-relative">
                                <input 
                                  #place
                                  name="name" id="name" type="text" class="form-control-story transparent" placeholder="Lugar/Entorno :" formControlName="place">
                                <span class="error" *ngIf="thirdFormGroup.controls['place'].hasError('required')">Título es requerido
                                </span>
                            </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-3">
                            <div class="form-icon position-relative">
                                <input 
                                  #context
                                  name="name" id="name" type="text" class="form-control-story transparent" placeholder="Contexto :" formControlName="context">
                                <span class="error" *ngIf="thirdFormGroup.controls['context'].hasError('required')">Título es requerido
                                </span>
                            </div>
                        </div>
                      </div>
                    </div>
                      <div class="content-buttons">
                        <button 
                          class="btn btn-primary mt-2 me-2 btn-steeper"
                          cdkStepperNext>
                          Siguiente
                      </button>
                      </div>
                    </form>
                  </cdk-step>

                  <cdk-step [stepControl]="thirdFormGroup" [optional]="false">
                    <form class="form-steeper-content" [formGroup]="thirdFormGroup"  #formRef="ngForm">
                      <ng-template cdkStepLabel>
                        <div class="step-bullet">5</div>
                        <div class="step-title steeper-xs-title">Vista Previa </div>
                      </ng-template>
                      <div class="row">
                        <div class="col-md-12">
                          <h2><p>Parametros de tu historia.</p></h2>
                          <br>
                          <p><strong>Plan: </strong>{{pricing.value}}</p>
                          <p><strong>Título: </strong>{{title_book.value}}</p>
                          <p><strong>Personaje Principal: </strong>{{principal_character.value}}</p>
                          <p><strong>Personaje Secundario: </strong> </p>
                              <span *ngFor="let item of secondary_characters().controls; let i=index">
                                {{ i + 1 }}.- {{ item.controls['name'].value }}
                              </span>
                          <p><strong>Lugar donde se desarrollara la narrativa: </strong>{{place.value}}</p>
                          <p><strong>El contexto de tu narrativa: </strong>{{context.value}}</p>
                          <br>
                          <br>
                          <p><strong>Presiona "Generar Historia" si deseas proceder a generarla: </strong></p>
                        </div>
                      </div>
                      <div class="content-buttons">
                        <button 
                          class="btn btn-primary mt-2 me-2 btn-steeper"
                          (click)="generateStory(content)"
                          cdkStepperNext
                          [disabled]="isDone">
                          Generar Historia
                      </button>
                      </div>
                    </form>
                  </cdk-step>

                  <cdk-step [stepControl]="thirdFormGroup" [optional]="false" [state]="'done'">
                    <form class="form-steeper-content" [formGroup]="thirdFormGroup"  #formRef="ngForm">
                      <ng-template cdkStepLabel>
                        <div class="step-bullet">6</div>
                        <div class="step-title steeper-xs-title">Final</div>
                      </ng-template>
                      <div class="row">
                        <div class="col-md-12">
                          <div *ngIf="isLoading">
                            <p>Has terminado, ahora la magia comienza!.</p>
                            <p><strong>generando historia, espera unos segundos...</strong></p>
                          </div>
                          <div *ngIf="isDone">
                            <p>Gracias por utilizar el Generador de Historias, espero te haya gustado!.</p>
                            <p>No olvides que tus historias quedaran almacenadas, puedes verlas cuando quieras.</p>
                          </div>
                        </div>
                      </div>
                      <div class="content-buttons">
                        <button 
                          class="btn btn-primary mt-2 me-2 btn-steeper"
                          (click)="reset()">
                          Comenzar denuevo
                        </button>
                      </div>
                    </form>
                  </cdk-step>

                  
                </ng-stepper>

                <ng-template #content let-c="close" let-d="dismiss">
                  <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">{{ firstFormGroup.get('title_book').value }}</h4>
                    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
                  </div>
                  <div class="modal-body">
                    <form>
                      <div class="mb-3">
                        <label >Capitulo 1</label>
                        <div class="input-group" [innerHTML]="story"></div>
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Cerrar</button>
                    <button type="button" class="btn btn-primary" (click)="c('Save click')">¿Quieres continuar esta historia?</button>
                  </div>
                </ng-template>
              </div>
            </div>
        </div>
    </div>
</div>
