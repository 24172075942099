import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-story-builder',
  templateUrl: './story-builder.component.html',
  styleUrls: ['./story-builder.component.css'],
})
export class StoryBuilderComponent implements OnInit {
  storyMode: number = 0;
  isSelectionChileHistory: boolean = false;

  modeOptions: Array<{}> = [
    {
      name: 'Historia Libre',
      option: 1
    },
    {
      name: 'Historias de Chile',
      option: 2
    }
  ]

  
  constructor(
    private router: Router,
  ){}

  ngOnInit(): void {}

  goTo(mode, params): void {
    this.router.navigate([`story-${mode}`], { 
      //skipLocationChange: true,
      state: {data: params},
    })
  }

  chileHistory(): void{
    this.isSelectionChileHistory = true
  }

}
