import { Component } from '@angular/core';

@Component({
  selector: 'app-story-free',
  templateUrl: './story-free.component.html',
  styleUrls: ['./story-free.component.css']
})
export class StoryFreeComponent {
  Menuoption = 'center';
  Settingicon = true
  activeSwitcher = true
  storyData = null

  constructor(){}

  ngOnInit(): void {}

  storyisDone(){
    this.activeSwitcher = false
    setTimeout(() => {
      this.activeSwitcher = true
    }, 700);
  }

  storyEmmiter($event){
    this.storyData = $event
  }

}
