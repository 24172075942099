export const environment = {
  production: false,
  site: "https://taleweaverai.com",
  api: "https://backend-mihistoria-c2cbepyb3a-uc.a.run.app",

  firebase: {
    apiKey: "AIzaSyAavlNf4f9jBxB1GcggYLcQAlZKe6SfxCM",
    authDomain: "taleweaver.web.app",
    projectId: "taleweaverai",
    storageBucket: "taleweaverai.appspot.com",
    messagingSenderId: "706452669763",
    appId: "1:706452669763:web:64e0e43cb589cbc4a15bf6",
    measurementId: "G-ECD3WZTP4Z"
  },
  module_context: {
    core: {
      evaluations: '/evaluations',
      gamification: '/gamification'
    },
    mercadopago: '/mercadopago',
    paypal: '/paypal'
  },
};