import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CharacterInstance, CharacterType } from './character/character';
import * as uuid from 'uuid';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { StoryService } from 'src/app/shared/services/story.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonFunctionalityComponent } from 'src/app/shared/common/common-functionality-component';
import { Router } from '@angular/router';
import { TransversalVariables } from 'src/app/shared/transversalVariables';


@Component({
  selector: 'app-story-maker',
  templateUrl: './story-maker.component.html',
  styleUrls: ['./story-maker.component.css']
})
export class StoryMakerComponent extends CommonFunctionalityComponent implements OnInit {
  @ViewChild("notEnoughtCredits") modalNotEnoughtCredits: ElementRef;
  @ViewChild("errorStory") modalErrorStory: ElementRef;
  @Output() storyIsDone: EventEmitter<boolean> = new EventEmitter
  @Output() storyEmmiter: EventEmitter<any> = new EventEmitter
  

  characters: CharacterInstance[] = [];
  currentSize: number = this.characters.length;
  historyType: 'basic'|'advanced' = 'basic';
  title: string = '';
  location: string = '';
  context: string = '';
  isLoading: boolean = false;
  story_title: string = '';
  story_id: string = '';
  story_text: string = '';
  content: any;
  closeResult = '';
  pricing: string = ''
  pricings: Array<{}> = [];
  canGenerateStory: boolean = false;
  notEnoughCredits: boolean = false;
  totalCredits: number = 0;
  openBook: boolean = false
  validNamesCharacters: boolean = false;
  synth: SpeechSynthesis;
  
  // build text variables
  i = 0;
  pass: boolean = true;
  text_build: Array<string> = [
    'Análisis contextual de la historia...',
    'Generando relaciones entre contexto y personajes...',
    'Posicionando al personaje principal en el lugar de la historia ...',
    'Incorporando personajes secundarios...',
    'Generando ilustraciones...',
    'Estamos finalizando la creación de tu historia, ajustando últimos detalles, espera unos segundos...',
  ]
  //...

  
  constructor(
    private _screenLoadingService: LoadingService,
    private _storyService: StoryService,
    private _modalService: NgbModal,
    private _transversalVariables: TransversalVariables,
    public override router:Router
  ){
    super(router)
    this._screenLoadingService.show()
    this.synth = window.speechSynthesis;

  }


  ngOnInit(): void {
    this.synth.cancel();

    this._screenLoadingService.show()
    this.getCredits()
  }

  /**
 * Track by function for ngFor loops
 *
 * @param index
 * @param item
 */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  addCharacterButtonClicked() {
    if(this.currentSize === 0)
    {
      let newCharacter: CharacterInstance = {
        id: uuid.v4(),
        //name: 'Personaje ' + Math.floor(Math.random() * 5000).toString(),
        name: '',
        type: CharacterType.Main,
        photo: ''
      }
      let result = this.validateHistoryCost()
      if (result){
        this.characters.push(newCharacter);
        this.currentSize++;
      }
    }
    else
    {
      let newCharacter: CharacterInstance = {
        id: uuid.v4(),
        //name: 'Personaje ' + Math.floor(Math.random() * 5000).toString(),
        name: '',
        type: CharacterType.Secondary,
        photo: ''
      }
      let result = this.validateHistoryCost()

      if (result){
        this.characters.push(newCharacter);
        this.currentSize++;
        this.validateNameCharacters()
      }
    }

  }

  onCharacterDelete(event) {
    this.characters = this.characters.filter(character => character.id !== event.id);
    this.currentSize--
    this.validateHistoryCost()
  }

  onCharacterEdit(event) {
    this.characters = this.characters.map(character => {
      if(character.id === event.id)
      {
        return event;
      }
      return character;
    });
    this.validateNameCharacters()
  }

  validateNameCharacters(){
    let validation = true
    this.characters.forEach(character => {
      if (character.name === '' || character.name === undefined){
        validation = false
      }
    })
    this.validNamesCharacters = validation
  }

  validateHistoryCost()
  {
    const countSecondaryCharacters = this.characters.filter(character => character.type === CharacterType.Secondary).length;
    if(countSecondaryCharacters >= 2 && this.totalCredits >=2)
    {
      this.historyType = 'advanced';
      this.pricing = '64696a2ff39fd65bf3422595';
      return true
    }
    else if((countSecondaryCharacters >= 0  && countSecondaryCharacters < 1) && this.totalCredits >=1)
    {
      this.historyType = 'basic';
      this.pricing = '64696917f39fd65bf342258e';
      return true

    }
    else{
      this._modalService.open(this.modalNotEnoughtCredits, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
      return false
    }

  }

  generateStory(content) {
    this._screenLoadingService.show()
    this._screenLoadingService.text('Analizando parametros de la Historia...')
    
    //Parametros
    let data  = {title:this.title, location: this.location, context: this.context, charactes: this.characters }
    this._screenLoadingService.text('Generando historia...')

    this.buildText()
    this.isLoading = true
    let sendData = {}
    const principalCharacter = data.charactes.filter(character => character.type == CharacterType.Main)[0]
    sendData = {
      title: data.title,
      pricing:  this.pricing,
      principal_character: {
        name: principalCharacter.name,
        photo: principalCharacter.photo
      },
      secondary_characters: data.charactes.filter(character => character.type == CharacterType.Secondary),
      place: data.location,
      context: data.context
    }

    this._storyService.generate(sendData).subscribe(
      (result:any) => {
      if (result && result.status == true && 'data' in result){
        this.pass = false 
        this.openStory(content, data, result)
        this._screenLoadingService.hide()
        this._screenLoadingService.text('')
        this._transversalVariables.setCredits( this._transversalVariables.getCredits()-1 )
      }else{
        this._screenLoadingService.hide()
        this._screenLoadingService.text('')

        this._modalService.open(this.modalErrorStory, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {

            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          },
        );
      }
    },
    (error) => {
      this._screenLoadingService.hide()
      this._screenLoadingService.text('')
      this._modalService.open(this.modalErrorStory, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
      console.error(error);
    });
  }

  openStory(content, data, result){
    this.story_title = data.title
    setTimeout(() => {
      data['id'] = result.data._id
      data['content'] = result.data.content
      data['recompense'] = result.data.recompense
     
      setTimeout(() => {
        if ( window.innerWidth > 1000 && window.innerHeight > 950 ){
          this.openBook = false;
          this.story_text = data
          this.storyEmmiter.emit(this.story_text)
          //this.bookStyle()
        }else{
          this.story_text = data.content
          this.modalStyle(content)    
        }
      }, 500);
    }, 500);

   
    this.storyIsDone.emit(true)
  }

  bookStyle(){
    this.openBook = true;
  }

  modalStyle(content){
    this._modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  buildText(){
    if(this.i < 6 && this.pass){
      setTimeout(() => {
        this._screenLoadingService.text(this.text_build[this.i])
        this.i++
        this.buildText()
      }, 10000);
    }else{
      if(!this.pass){
        this._screenLoadingService.hide()
        this._screenLoadingService.text('')
      }
    }
  }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  getCredits(){
    this.pricings = [];
    this.totalCredits = 0
    this._storyService.getCredits().subscribe((result: any) =>{
      if (result.data.length > 0){
          result.data.map((x: any) => {
            if (x.credits > 0){
              this.pricings.push(x)
              this.canGenerateStory = true
              this.totalCredits+=x.credits
            }
          })
      }
    })
  }

  onActiveBook(){
    this.openBook = false
  } 

}
