import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Character } from './character';

@Component({
  selector: 'character',
  templateUrl: './character.component.html',
  styleUrls: ['./character.component.css']
})
export class CharacterComponent implements AfterViewInit{
  @ViewChild('videoElement') videoElement: ElementRef;
  @ViewChild('canvasElement') canvasElement: ElementRef;

  @Input('character') character: Character | null = null;
  @Input('edit') characterEdit: Boolean = false;
  @Output() deletedCharacter = new EventEmitter<any>();
  @Output() editedCharacter = new EventEmitter<any>();

  constructor() {

  }

  ngAfterViewInit() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoElement.nativeElement.srcObject = stream;
          this.videoElement.nativeElement.play();
        })
        .catch(error => {
          console.error("Error accessing the camera", error);
        });
    }
  }

  editButtonClick(){
    this.characterEdit = true;
  }

  editedCharacterChange(){
  }

  deleteButtonClick(){
    this.deletedCharacter.emit({...this.character});
  }

  focusOutFunction()
  {
    if(event.target['value'] !== ''){
      this.characterEdit = false;
    }else{
      this.characterEdit = true;
    }
    this.editedCharacter.emit({...this.character });

  }


  takePhoto(){
    const context = this.canvasElement.nativeElement.getContext('2d');
    context.drawImage(this.videoElement.nativeElement, 0, 0, 150, 112);
    // Aquí puedes guardar la imagen o hacer algo con ella
    const dataUrl = this.canvasElement.nativeElement.toDataURL('image/png');
    this.character.photo = dataUrl
    this.editedCharacter.emit({...this.character });


  }

}
