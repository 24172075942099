import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoryService } from 'src/app/shared/services/story.service';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css'],
})
export class EvaluationComponent implements OnInit{
  @Input("questionsEvaluation") data: any
  @Input("storyText") storyText: any
  @Output() evaluationDoneEmmit = new EventEmitter<any>();
  questions: Array<any> = []
  answers: Array<any> = ['', '', '', '', '', '']
  inEvaluation: boolean = false
  resolveEvaluation: boolean = false
  correctAnswers: number = 0
  questionsForm: FormGroup;
  evaluationResults: {} = {}

  
  constructor(
    private fb: FormBuilder,
    private _storyService: StoryService
  ){

  }

  ngOnInit(): void {
    this.questionsForm = this.fb.group({});
    const regex = /\d+\.\s(.+?)(?=\n\d+\.|$)/gs
    let match;
    while ((match = regex.exec(this.data.content)) !== null) {
      this.questions.push({text: match[1].trim(), is_correct: false, motive: ''});
    }
    this.questions.forEach((question, index) => {
      this.questionsForm.addControl(`answer_${index.toString()}`, this.fb.control('', Validators.required));
    });
  }

  Evaluate(){
    this.inEvaluation = true
    let answer = this.prepareDataAnswers(this.questionsForm.value)
    let sendData = {
      id: this.storyText._id,
      story: this.storyText.content,
      answers: answer
    }
    this._storyService.evaluateTest(sendData).subscribe(answers =>{
      this.resolveEvaluation = true
      let result = answers.data.content
      console.log(result)
      for(let [i, answer] of result.entries()){
        this.questions[i].is_correct = answer.respuesta.trim()
        this.questions[i].motive = answer.motivo
        if (answer.respuesta.trim() == 'correcta'){
          this.correctAnswers++
          this.answers[i] = answer.respuesta.trim()
        }
      }

      let {message, img} = this.getMessageEvaluation()
      
      this.evaluationResults = {
        totalCorrectAnswers: this.correctAnswers,
        message: message,
        img: img

      }
      console.log('this.evaluationResults')
      console.log(this.evaluationResults)
      this.evaluationDoneEmmit.emit(this.evaluationResults)

      //const regex = /\d+\.\s(correcta|incorrecta)/g;
      //let match;
      /*let i=0
      while ((match = regex.exec(answers.data.content)) !== null) {
        this.questions[i].is_correct = match[1].trim()
        this.answers.push(match[1].trim());
        i++
      }*/

    })
  }

  getMessageEvaluation(){
    let message = ''
    let img = ''
    if(this.correctAnswers >= 0 && this.correctAnswers <= 2){
      message = 'Aun debes mejorar, ¡tu puedes!'
      img = 'regular'
    }
    if(this.correctAnswers === 3){
      message = 'Vas por buen camino, ¡Animo!'
      img = 'regular'
    }

    if(this.correctAnswers >= 4 && this.correctAnswers < 6){
      message = '¡Felicidades, lo comprendes muy bien!'
      img = 'good'
    }
    if(this.correctAnswers == 6){
      message = '¡¡Felicidades, lo haz conseguido!!'
      img = 'win'
    }

    return {message, img}
  }

  prepareDataAnswers(answers){
    let i = 1;
    let totalQuestions = 6
    let data = ''

    while(i <= totalQuestions){
      let answer = `answer_${i-1}`
      data+=`${i}. ${this.questions[i-1].text}. Respuesta: ${answers[answer]}. \n`
      i++
    }
    return data
  }

}
