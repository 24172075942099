import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent {
  @Input('storyText') storyText: any;
  @Input('isEvaluationDone') isEvaluationDone: boolean = false
  @Input('evaluationResults') evaluationResults: {} = {}
  @Output() createTestEmmit = new EventEmitter();
  @Output() finishEvaluationEmmit = new EventEmitter();

  allTextsBtn: Array<string> = ['Comenzar Prueba', 'Prueba Iniciada...','Reintentar', 'Prueba Finalizada']
  textBtn: string = this.allTextsBtn[0]
  activeBtn: boolean = true


  createTest(){
    this.textBtn = this.allTextsBtn[1]
    this.activeBtn = false
    this.createTestEmmit.emit()
  }

  finishEvaluation(){
    this.finishEvaluationEmmit.emit()
  }

}

