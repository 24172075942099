<div class="app-options">
    <!-- Text-to-Speach -->
    <app-text-to-speach [storyText]="storyText.content"></app-text-to-speach>

    <!-- Evaluations -->
    <div class="col-md-12 form-check form-check-inline">
        <div class="mb-0">
            <div class="title-section">
                <label class="form-label title-evaluation">Criterio a Evaluar</label>
            </div>
            <div class="form-check check-section">
                <input class="form-check-input" type="checkbox" checked value="" id="flexCheckDefault1">
                <label class="form-check-label" for="flexCheckDefault1">Comprensión Lectora</label>
            </div>
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2" disabled>
            <label syle="color:gray; font-style: italic" class="form-check-label" for="flexCheckDefault2">Historia (Pronto)</label>
        </div>
        </div>
    </div>
    <div class="col-md-12 form-check form-check-inline" *ngIf="!isEvaluationDone">
        <div class="mb-0">
            <div class="cont-btn">
                <button class="btn btn-primary" (click)="createTest()" [disabled]="!activeBtn && !isEvaluationDone"> 
                    <div>
                    <i *ngIf="!activeBtn && !isEvaluationDone" class="spinner-grow" role="status"></i>
                    <span class="txt-btn">{{ textBtn }}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-12 form-check form-check-inline cont-result-evaluation" *ngIf="isEvaluationDone">
        <div class="mb-0">
            <div class="card-body">
                <h5 class="card-title">Resultado Evaluación</h5>
                <p class="text-muted">{{ evaluationResults.message }}</p>
                <p class="text-muted calification">{{ evaluationResults.totalCorrectAnswers }}/6</p>
            </div>
            <img src="assets/images/book/{{evaluationResults.img}}-evaluation.png" class="img-fluid" alt="">
            <div class="cont-btn">
                <button class="btn btn-primary" (click)="finishEvaluation()"> 
                    <div>
                        <span class="txt-btn">Finalizar Evaluación</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
