import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaypalService } from 'src/app/shared/services/paypal';

@Component({
  selector: 'app-page-invoice',
  templateUrl: './page-invoice.component.html',
  styleUrls: ['./page-invoice.component.css']
})
export class PageInvoiceComponent implements OnInit {

  Menuoption = 'center';
  Settingicon = true
  hideFooter = true;
  payment_id: number;
  data: any;
  constructor(
    private _route: ActivatedRoute,
    private _service: PaypalService
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe((result: any) =>{
      let dataSend = {
        id: result.id
      }
      this.data = result
      this._service.setPayment(dataSend).subscribe((data: any) => {
      })
    })


  }

}
