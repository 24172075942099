import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { StoryService } from 'src/app/shared/services/story.service';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-story-form-pro',
  templateUrl: './story-form-pro.component.html',
  styleUrls: ['./story-form-pro.component.css']
})
export class StoryFormProComponent implements OnInit {
  @Output() callBook = new EventEmitter();

  story: string = '';
  closeResult = '';
  promt: string = '...';
  continue: string = 'Continuar Historia';
  suspense: string = 'generar suspenso';
  open: string = 'dejar historia abierta';
  max_repetitions = 2;
  canGenerateStory: boolean = false;
  i = 0;
  
  pricings: Array<{}> = [];
  deep = 0;
  isLoading: boolean = false;
  isDone: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  pricingFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  isEditable: boolean = true
  content: any;
  pass: boolean = true;
  text_build: Array<string> = [
    'Análisis contextual de la historia, Compeltada...',
    'Posicionando al personaje pricipal en el lugar de la historia ...',
    'Incorporando personajes secundarios...',
    'Estamos finalizando la creacion de tu historia, ajustando últimos detalles...',
  ]

  constructor(
    private _fb: FormBuilder,
    private _service: StoryService,
    private modalService: NgbModal,
    private _configModal: NgbModalConfig,
    private _storyService: StoryService,
    private _loadingService: LoadingService
  ) { 
    this._configModal.backdrop = 'static';
		this._configModal.keyboard = false;
  }

  ngOnInit() {
    this._loadingService.show()
    this._storyService.getCredits().subscribe((result: any) =>{
      if (result.data.length > 0){
          result.data.map((x: any) => {
            if (x.credits > 0){
              this.pricings.push(x)
              this.canGenerateStory = true
            }
          })
      }
    })

    this.pricingFormGroup = this._fb.group({
      pricing: [null],
    });

    this.firstFormGroup = this._fb.group({
      title_book: ['', Validators.required],
    });

    this.secondFormGroup = this._fb.group({
      principal_character: ['', Validators.required],
      secondary_characters: this._fb.array([], Validators.required)
    });

    this.thirdFormGroup = this._fb.group({
      place: ['', Validators.required],
      context: ['', Validators.required]
    });

  }

  setPricing(pricing): void {
    this.pricingFormGroup.get('pricing').setValue(pricing.value)
  }

  async generateStory(content){
    this._loadingService.show()
    this._loadingService.text('Analizando parametros de la Historia...')
    this.buildText()
    this.isLoading = true
    let sendData = {}
    sendData = {
      title: this.firstFormGroup.get('title_book').value,
      pricing:  this.pricingFormGroup.get('pricing').value,
      principal_character: this.secondFormGroup.get('principal_character').value,
      secondary_characters: this.secondFormGroup.get('secondary_characters').value,
      place: this.thirdFormGroup.get('place').value,
      context: this.thirdFormGroup.get('context').value
    }
    this._service.generate(sendData).subscribe((result:any) => {
      this.pass = false
      this.story = `${result.data}`
      this._loadingService.hide()
      this._loadingService.text('')
      this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
    })
  }

  newSecondaryCharacter(): FormGroup {  
    return this._fb.group({  
      name: '',
    })  
  }  

  secondary_characters(): FormArray {  
    return this.secondFormGroup.get("secondary_characters") as FormArray  
  }  
  
  addSecondaryCharacter(){ 
    switch (this.pricingFormGroup.get('pricing').value) {
      case '64696917f39fd65bf342258e':
        if(this.secondary_characters().length < 1){
          this.secondary_characters().push(this.newSecondaryCharacter());  
        }
      break;
      
      case '64696a2ff39fd65bf3422595':
        if(this.secondary_characters().length < 3){
          this.secondary_characters().push(this.newSecondaryCharacter());  
        }
      break;

      case '64696a3df39fd65bf3422596':
        if(this.secondary_characters().length < 7){
          this.secondary_characters().push(this.newSecondaryCharacter());  
        }
      break;
    
      default:
        break;
    }
   
  } 

  removeSecondaryCharacter(i:number) {  
    this.secondary_characters().removeAt(i);  
  }  


  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  reset(){
    window.location.reload()
  }


  buildText(){
    this.i ++
    if(this.i < 4 && this.pass)
      setTimeout(() => {
        this._loadingService.text(this.text_build[this.i])
        this.buildText()
      }, 10000);
  }

  goTo(page){

    switch (page) {
      case 'home':
        window.location.href = `${environment.site}`;
      break;
    
      default:
      break;
    }

  }

}


