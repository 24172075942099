export  enum CharacterType {
    Main = 'MAIN',
    Secondary = 'SECONDARY',
  }

export interface Character {
    name: string;
    type: CharacterType;
    photo: string;
  }

  export interface CharacterInstance extends Character {
    id: string;
  } 
