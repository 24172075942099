<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Planes </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Inicio</a></li>
          <li class="breadcrumb-item active" aria-current="page">Planes</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Price Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Selecciona un Plan</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Cada plan tiene una cantidad de créditos. <span
              class="text-primary fw-bold">TaleWeaver</span> generara Historias mientras aun tengas créditos.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-simple-pricing [simple_pricingData]="simple_pricingData"></app-simple-pricing>
  </div>
  <!-- Price End -->

  <!-- Testi Start -->
  <!--
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Client Reviews</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
    </div>
  </div>
  -->
</section>
<!--end section-->
<!-- Testi End -->

<!-- FAQ n Contact Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">¿ Cómo funcionan las recargas ?</h5>
            <p class="answer text-muted mb-0">Cada Plan tiene una cantidad de créditos disponibles, por ejemplo, la carga Básica tiene 10 créditos, es decir que 
              podras crear 10 Historias. Cuando se te acaben los créditos, y aun quieres generar más Historias deberas nuevamente recargar con algun Plan.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> ¿ Cómo funciona el proceso principal de recarga ?</h5>
            <p class="answer text-muted mb-0">Seleccionas un Plan, este te llevará a la plataforma de pago PayPal, donde podras realizar tu compra. Una vez que termines la compra,
              y esta haya sido exitosa, tendras los créditos en tu cuenta inmediatamente para comenzar a generar Historias.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> ¿ Dónde se crean las Historias ?</h5>
            <p class="answer text-muted mb-0">En el menu existe la opción "Creador de Historias", haz click allí.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> Es <span class="text-primary">TaleWeaver</span> Seguro ?</h5>
            <p class="answer text-muted mb-0">Claro que lo es. El pago es a través de la plataforma de pagos PayPal, por lo cual es esta entidad Internacional quien maneja el flujo de pago, de esta forma,
              tu compra es 100% segura.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Tengo preguntas, ¿dónde puedo hacerlas?</h4>
          <p class="text-muted para-desc mx-auto">Nuestro equipo de <span class="text-primary fw-bold">TaleWeaver</span>
            estara atento a tus dudas a través de nuestro medio de contacto por email. 
            <span class="text-primary fw-bold">contacto@taleweaverai.com </span></p>
          <!--<div class="mt-4 pt-2">
            <a routerLink="/contact" class="btn btn-primary">Contáctanos <i class="mdi mdi-arrow-right"></i></a>
          </div>-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- FAQ n Contact End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->