import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StoryService } from 'src/app/shared/services/story.service';

@Component({
  selector: 'app-sidebar-mission',
  templateUrl: './sidebar-mission.component.html',
  styleUrls: ['./sidebar-mission.component.css']
})
export class SidebarMissionComponent {
  public option: string = 'chile-independency';
  public isSidebarOpen = false;
  /*public missions = [
    { author:'Juan Martínez de Rozas', name: 'El Primer Grito de Libertad' },
    { author:'Camilo Henríquez',       name: 'La Aurora de Chile' },
    { author:'José Miguel Carrera',    name: 'Defendiendo la Patria Vieja' },
    { author:'Manuel Rodríguez',       name: 'La Resistencia en la Clandestinidad' },
    { author:"Bernardo O'Higgins",     name: 'El Cruce de los Andes' },
    { author:'José de San Martín',     name: 'La Batalla de Chacabuco' },
    { author:"Paula Jaraquemada",      name: 'El Refugio de Jaraquemada' },
    { author:"Bernardo O'Higgins",     name: 'La Batalla de Maipú' },
    { author:"Bernardo O'Higgins",     name: 'Consolidando la Independencia' },
  ];*/
  public missions = []

  constructor(
    private router: Router,
    private _storyService: StoryService
  ){
    //this.option = this.router.getCurrentNavigation()?.extras.state?.data;
  }

  ngOnInit(): void {
    console.log(this.option);
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

}