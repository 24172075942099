import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StoryService {

  constructor(
    private http: HttpClient) { }
  
  generate(data) {
    const url = `${environment.api}/create`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  getCredits() {
    const url = `${environment.api}/get-credits`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  getStories(page) {
    const url = `${environment.api}/get-stories/${page}`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  createTest(data){
    console.log(data)
    const url = `${environment.api}/create-test`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  evaluateTest(data){
    const url = `${environment.api}${environment.module_context.core.evaluations}/create`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  getMilestones(history_id){
    const url = `${environment.api}/get-milestones/${history_id}`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  getHistory(){
    const url = `${environment.api}/get-histories`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  startGame(){
    const url = `${environment.api}/get-history-game`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  startCampaing(data){
    const url = `${environment.api}/start-campaign`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  historyStart(data){
    const url = `${environment.api}/history-start`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  saveTeam(data){
    const url = `${environment.api}/save-team`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  travelPast(data){
    const url = `${environment.api}/travel-past`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  readMilestoneStory(campaign_id, milestone_id){
    const url = `${environment.api}/read-milestone-story/${campaign_id}/${milestone_id}`
    return this.http.get(url).pipe(map((x: any) => x));
  }

}
