import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.css']
})

/**
 * Account Profile Component
 */
export class AccountProfileComponent implements OnInit {
  userProfile: any
  isSaving: boolean = false
  listRecompenses: {} = {}
  listItemsRecompense: {} = {}
  recompense: {} = {}
  titles: Array<{}> = []
  badges: Array<{}> = []
  skins: Array<{}> = []
  pets: Array<{}> = []
  closeResult = '';
  selectedGender: string = ''
  avatarForm: FormGroup
  isRecompenseSelected: boolean = false
  isRecompenseOpen: boolean = false
  imageChanged: boolean = false;
  currentImage: string = 'assets/images/book/treasure.png';
  titleImage: string = 'assets/images/book/parchment.png';
  audio = new Audio('assets/sounds/celtic.mp3');
  // Set Topbar Option
  Settingicon = true
  Menuoption = 'center';
  navClass = 'nav-light';
  showSelectPj = true;
  private modalRef: NgbModalRef | null = null;

  avatar = {
    head: '',
    body: '',
    pet: ''
  }

  constructor(
    private _userService: UserService,
    private _modalService: NgbModal,
    private fb: FormBuilder,
  ) { }

  get progressWidth(): string{
    if (this.userProfile?.next_level === 0) {
      return '0%';
    }
    return `${(this.userProfile?.exp / this.userProfile?.next_level) * 100}%`;
  }

  ngOnInit(): void {
    this.getProfileUser()
    this.getRecompenses()

  }

  selectCharacters(modal, gender): void{
    this.selectedGender = gender

    this.modalRef = this._modalService.open(modal, {
      size: 'sm',
      windowClass: 'modal-holder',
      centered: true
    });

    this.modalRef.result.then(
      (result) => {
        console.log('Modal cerrado con:', result);
      },
      (reason) => {
        console.log('Modal cerrado por:', reason);
      }
    );
  }

  selectedCharacterSave() {
    let sendData = {
      gender: this.selectedGender
    }
    this._userService.editUserProfile(sendData).subscribe(
      (response) => {
        this.userProfile = response.data
        this.buildCharacter()
        this.modalRef.close()
      })
  }


  getProfileUser():void {
    this._userService.getProfileUser().subscribe(
      (response) => {
        this.userProfile = response.data
        if (this.userProfile.gender){
          this.buildCharacter()
        } else {
          this.showSelectPj = false;
        }
      })
  }

  buildCharacter():void {
    this.createForm();
    this.subscribeToFormChanges();
    this.setInitClothesCharacter();
  }

  openArchievement(content: any) {
    this.modalRef = this._modalService.open(content, {
      size: 'xl',
      windowClass: 'modal-holder',
      centered: true
    });

    this.audio.play();

    // Escucha el evento de cierre del modal
    this.modalRef.result.then(
      (result) => {
        console.log('Modal cerrado con:', result);
        this.onModalClose();
      },
      (reason) => {
        console.log('Modal cerrado por:', reason);
        this.onModalClose();
      }
    );
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close(); // Puedes pasar un valor para el 'result'
      this.onModalClose();
    }
  }

  onModalClose() {
    // Detén el audio cuando el modal se cierra
    this.audio.pause();
    this.audio.currentTime = 0;
    this.modalRef = null; // Resetea la referencia del modal
  }

  createForm(): void{
    this.titles = this.userProfile.items.titleItems
    this.badges = this.userProfile.items.badgeItems
    this.skins = this.userProfile.items.skinItems
    this.pets = this.userProfile.items.petItems

    this.avatarForm = this.fb.group({
      head:  ['', Validators.required],
      body:  ['', Validators.required],
      pet:   ['', Validators.required],
      title: ['', Validators.required],
      badge: ['', Validators.required]
    })
    
    this.loadForm()
  }

  loadForm():void {
    let title = (this.userProfile.title)? this.userProfile.title._id: ''
    let badge = (this.userProfile.badge)? this.userProfile.badge._id: ''
    
    this.avatarForm.patchValue({
      head: this.userProfile.skin.head,
      body: this.userProfile.skin.body,
      pet: this.userProfile.skin.pet,
      title: title,
      badge: badge
    });
  }

  subscribeToFormChanges(): void{
    this.avatarForm.get('head').valueChanges.subscribe(value => {
      this.onHeadstyleChange(value);
    });

    this.avatarForm.get('body').valueChanges.subscribe(value => {
      this.onBodystyleChange(value);
    });

    this.avatarForm.get('pet').valueChanges.subscribe(value => {
      this.onPetChange(value);
    });
  }

  setInitClothesCharacter(): void{
    this.avatarForm.get('head')?.setValue(this.avatarForm.get('head').value);
    this.avatarForm.get('body')?.setValue(this.avatarForm.get('body').value);
    this.avatarForm.get('pet')?.setValue(this.avatarForm.get('pet').value);
  }

  onHeadstyleChange(value):void {
    this.avatar.head = value;
  }

  onBodystyleChange(value):void {
    this.avatar.body = value;
  }

  onPetChange(value):void {
    this.avatar.pet = value;
  }

  saveCharacter():void {
    this.isSaving = true;
    let dataSend = {
      title: (this.avatarForm.get('title').value)? this.avatarForm.get('title').value: null,
      skin: {
          head: this.avatarForm.get('head').value,
          body: this.avatarForm.get('body').value,
          pet: this.avatarForm.get('pet').value,
      },
      badge: (this.avatarForm.get('badge'))? this.avatarForm.get('badge').value: null,
    }

    this._userService.editUserProfile(dataSend).subscribe(
      (response) => {
        this.isSaving = false;
        this.userProfile.title = response.data.title
        this.userProfile.badge = response.data.badge
      })
  }

  getRecompenses():void {
    this._userService.getRecompenses().subscribe(
      (response) => 
        {
          this.listRecompenses = response.data
        })
  }

  selectedRecompense(listItemsRecompense):void {
    this.isRecompenseSelected = true
    this.listItemsRecompense = listItemsRecompense
  }

  saveRecompense():void {
    if (this.imageChanged) {
      return; 
    }
    this.changeImage()
    this._userService.setRecompense(this.listItemsRecompense).subscribe(
      (response) => 
        {
          this.recompense = response.data.recompense
          this.userProfile = response.data.userProfile

          this.titles = this.userProfile.items?.titleItems
          this.badges = this.userProfile.items?.badgeItems
          this.skins = this.userProfile.items?.skinItems
          this.pets = this.userProfile.items?.petItems

          this.getRecompenses()
        })
  }

  changeImage(): void {
    if (!this.imageChanged) {
      this.currentImage = 'assets/images/book/treasure_open.png';
      this.imageChanged = true;
    }
  }

  closeRecompense(){
    this.isRecompenseSelected = false
  }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}
