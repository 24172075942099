import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { LoadingService } from '../shared/loading/loading.service';
import { environment } from 'src/environments/environment';

export interface ProfileGoogle {
  email: string;
  id: string;
  name: string;
  picture?: string;
}


export enum EnumAuthProvidersID {
  google = 'google.com',
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  credentialData: any;
  userData: any;
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
   constructor(
    public fbAuth: AngularFireAuth,
    public router: Router,
    private _userService: UserService,
    private _loadingService: LoadingService
  ) {
    this.fbAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = JSON.parse(JSON.stringify(user));
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', 'null');
      }
    });
  }

    /**
   * Setter & getter for access token
   */
    get accessToken(): string {
      return localStorage.getItem('accessToken') ?? '';
    }
    set accessToken(token: string) {
      localStorage.setItem('accessToken', token);
    }
  

    /**
     * Sign in using the access token
     */
    //signInUsingToken(): Observable<any> {
    signInUsingToken(name=null, email=null, redirect_to=null): Observable<any> {
      // Sign in using the token
      this.fbAuth.authState.subscribe((response) => {
        if (response) {
          const { multiFactor } = response;
          const { user } = multiFactor as any;

          if (user.accessToken) {
            this.accessToken = user.accessToken;
            if(name && email){
              this._userService.create(name, email).subscribe((result: any) =>{
                if(redirect_to){
                  window.location.href = `${environment.site}/${redirect_to}`;

                }else{
                  this.router.navigateByUrl('/story-builder');
                }
              })
            }
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          /*this._userService.user = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            avatar: user.photoURL,
            status: '',
          };*/
        }
      });
      return this.fbAuth.authState;
    }
    
    /**
     * Sign out
     */
    signOut(): Observable<any> {
      // Remove the access token from the local storage

      this.fbAuth.signOut().then((user) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        // Set the authenticated flag to false
        this._authenticated = false;
        window.location.reload()
      })

      // Return the observable
      return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
      // Check if the user is logged in
      if (this._authenticated) {
        return of(true);
      }

      // Check the access token availability
      if (!this.accessToken) {
        this._authenticated = false;
        return of(false);
      }

      // Check the access token expire date
      //if (AuthUtils.isTokenExpired(this.accessToken)) {
      //return of(false);
      //}

      // If the access token exists and it didn't expire, sign in using it
      return this.signInUsingToken();
    }
  
    /**
     * login Oauth2
     * */
    async login(redirect_to=null) {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('email');

      const { credential, additionalUserInfo } = await this.fbAuth.signInWithPopup(
        provider
      );

      if (credential) {
        this._loadingService.show()
        // Store the access token in the local storage
        if (credential.providerId === EnumAuthProvidersID.google) {
          const { profile } = additionalUserInfo;
          const { email, id, name, picture } = profile as ProfileGoogle;

          //Verify user is active and is valid in own system
          this.accessToken = (credential as any).accessToken;
          this.signInUsingToken(name, email, redirect_to);

          // Set the authenticated flag to true
          this._authenticated = true;

          return { status: true, name: name, email: email };
        }
      }
      return { status: false };
    }

}
