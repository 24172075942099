import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Howl } from 'howler';

@Component({
  selector: 'app-text-to-speach',
  templateUrl: './text-to-speach.component.html',
  styleUrls: ['./text-to-speach.component.css']
})
export class TextToSpeachComponent implements  OnInit, OnDestroy{
  @Input('storyText') storyText: string;

  backgroundSound: Howl;
  effectSounds: Howl;
  synth: SpeechSynthesis;
  soundMap: {} = {
    morning: 'assets/sounds/book/background_dark.mp3',
  };
  effectMap: {} = {
    wind: 'assets/sounds/book/wind_three.mp3',
  };

  constructor(){
    this.synth = window.speechSynthesis;
    this.synth.cancel();
    console.log('this.synth.speaking')
    console.log(this.synth.speaking)
  }

  ngOnInit(): void {
    console.log('this.synth.speaking')
    console.log(this.synth.speaking)
    this.synth.cancel();
  }

  AudioBook(){
    this.prepareText()
    const context = this.analyzeText(this.storyText);
    this.backgroundSound = new Howl({
      src: [this.soundMap[context]],
      loop: true,
      volume: 0.1
    });

    setTimeout(() => {
      this.effectSounds = new Howl({
        src: [this.effectMap['wind']],
        loop: true,
        volume: 2
      });
      this.effectSounds.play();
    }, 2000);
   

    this.backgroundSound.play();

    const utterThis = new SpeechSynthesisUtterance(this.storyText);
    utterThis.onend = () => {
      //this.backgroundSound.stop();
    };

    this.synth.speak(utterThis);
  }

  stop() {
    this.synth.cancel();
    //this.backgroundSound.stop();
    //this.effectSounds.stop();
  }

  prepareText(){
    this.storyText = this.storyText.replace(/<br\s*\/?>/gi, '');
    this.storyText = this.storyText.replace(/<\/?div[^>]*>/gi, '');
    this.storyText = this.storyText.replace(/<img[^>]*>/gi, '');
    this.storyText = this.storyText.replace(/<\/?span[^>]*>/gi, '');
  }

  analyzeText(text: string): string {
    if (text.includes("mañana clara")) {
      return "morning";
    } else if (text.includes("murmullo del viento")) {
      return "wind";
    } else if (text.includes("ciudad")) {
      return "city";
    } else if (text.includes("tensión")) {
      return "tension";
    }
    return "default";
  }

  ngOnDestroy() {
    this.stop();
  }

}
