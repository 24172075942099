import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WindowSizeService implements OnDestroy {
  private resizeSubscription: Subscription;
  private windowSize = new BehaviorSubject<{ width: number, height: number }>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  windowSize$ = this.windowSize.asObservable();

  constructor() {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(300)) // Ajusta el tiempo de debounce según tus necesidades
      .subscribe(() => {
        this.windowSize.next({
          width: window.innerWidth,
          height: window.innerHeight
        });
      });
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }
}