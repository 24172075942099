<div class="container-evaluation">
    <div class="row evaluation">
        <form [formGroup]="questionsForm">
            <div class="col-md-12" *ngFor="let question of questions; let i = index">
                <div class="mb-3">
                    <label class="form-label">{{ question.text }} <span class="text-danger">*</span></label>
                    <i-feather name="x" class="fea icon-sm icons" style="float: right; color:red" *ngIf="question.is_correct == 'incorrecta' && inEvaluation"></i-feather>
                    <i-feather name="check" class="fea icon-sm icons" style="float: right; color:rgb(84, 209, 88)" *ngIf="question.is_correct == 'correcta' && inEvaluation"></i-feather>
                    <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <textarea formControlName="answer_{{i}}" rows="4" class="form-control ps-5" [ngClass]="{'border-red': question.is_correct == 'incorrecta' && inEvaluation, 'border-green': question.is_correct == 'correcta' && inEvaluation}" placeholder="Tu Respuesta :"></textarea>
                        <p class="motive_error" *ngIf="question.motive !='' ">{{question.motive}}</p>
                    </div>
                </div>
            </div>
        </form>

        <div class="row" *ngIf="!resolveEvaluation">
            <div class="col-sm-12">
                <div class="cont-btn">
                    <button type="submit" class="btn btn-primary" (click)="Evaluate()" [disabled]="inEvaluation"> 
                        <div>
                        <i *ngIf="inEvaluation" class="spinner-grow" role="status"></i>
                        <span *ngIf="!inEvaluation" class="txt-btn">Evaluar</span>
                        </div>
                    </button>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>

    
</div>