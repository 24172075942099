import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-recompense',
  templateUrl: './notification-recompense.component.html',
  styleUrls: ['./notification-recompense.component.css']
})
export class NotificationRecompenseComponent implements OnInit {
  @Input('rewardReceived') rewardReceived: any;

  message: string = 'Has conseguido una recompensa!'
  isActive: boolean = false


  constructor(){}

  ngOnInit() {
    if (this.rewardReceived)
      if (this.rewardReceived.status === true){
        this.isActive = true;
        this.message = this.rewardReceived.message
      }
  }

  closeModal(){
    this.isActive = false
  }

}
