import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient) { }
  
  getUserData(){
    return JSON.parse(localStorage.getItem('user'))
  }

  create(name, email) {
    const data = {name: name, email: email}
    const url = `${environment.api}/create-user`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  getFeaturedStudents(){
    const url = `${environment.api}/featured-students`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  getUsersByCourse(){
    const url = `${environment.api}/get-users-by-course`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  getActhorsByHistory(history_id){
    const url = `${environment.api}/get-acthors-by-history/${history_id}`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  getProfileUser(){
    const url = `${environment.api}/get-profile-user`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  editUserProfile(data){
    const url = `${environment.api}/edit-user-profile`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  getRecompenses(){
    const url = `${environment.api}${environment.module_context.core.gamification}/get-recompenses`
    return this.http.get(url).pipe(map((x: any) => x));
  }

  setRecompense(data){
    const url = `${environment.api}${environment.module_context.core.gamification}/set-recompense`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

}
