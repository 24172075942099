<div class="background-book" *ngIf="showEvaluation"></div>

<section class="bg-half-170 bg-light d-table w-100  header-story">

  <app-sidebar-mission class="sidebar-mission"></app-sidebar-mission>

  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Independencia de Chile </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Inicio</a></li>
          <li class="breadcrumb-item active" aria-current="page">Creador de Historias</li>
        </ul>
      </nav>
    </div>
  </div>
  
</section>


<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
<div *ngIf="gameStarted">
<section class="section clearfix" *ngIf="gameStarted.userData.rol == 'student' ">

  <div *ngIf="gameStarted.campaign">
    <div class="col-md-12" *ngIf="gameStarted.campaign.is_travel_past">
      <div class="row">
        <div class="col-md-4" *ngFor="let milestone of milestones" (click)="checkMilestone(milestone._id) ? readMilestoneStory(modalBook, milestone) : null">
          <div class="storycard" [ngClass]="{ 'disabled': !checkMilestone(milestone._id)}">
            <div class="storycard-content">
                <div class="storycard-image" style="background-image: url('assets/images/story/milestone/independency-chile/milestone-{{ milestone.chapter }}.jpg');"></div>
                <div class="storycard-title">{{ milestone.name }}</div>
                <div class="storycard-text">
                  {{ checkMilestone(milestone._id) ? '¡Leer!' : '- Bloqueado -' }}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="teamForm" *ngIf="!gameStarted.campaign.is_travel_past">
      <div class="container mt-5 mt-sm-0" >
        <div class="row" >
          <!-- Selector de Usuarios -->
          <div class="col-lg-6 col-md-6 col-12">
            <div class="selector-container">
              <h4 class="selector-title">Selecciona tu Equipo</h4>
              <div class="row align-items-center">
                <ng-container>
                  <select class="form-select form-control" multiple  formControlName="team">
                    <option *ngFor="let user of users" [value]="user._id"> {{ user.name.toUpperCase() }}</option>
                  </select>
                </ng-container>    
              </div>
            </div>
          </div>

          <!-- Selector de Personajes Históricos -->
          <div class="col-lg-6 col-md-6 col-12">
            <div class="selector-container">
              <h4 class="selector-title">Seleccionar Personaje a Acompañar</h4>
              <div class="row align-items-center">
                <ng-container>
                  <select class="form-select form-control"  formControlName="acthor">
                    <option value="" disabled>Seleccionar...</option>
                    <option *ngFor="let acthor of acthors" [value]="acthor._id"> {{ acthor.name.toUpperCase() }}</option>
                  </select>
                </ng-container>    
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <!--<button class="btn btn-primary btn-lg" (click)="historyStart(modalBook)">Iniciar Historia</button>-->
          <button class="btn btn-primary btn-lg" (click)="saveTeam()">Guardar Equipo</button>
        </div>
      </div>
    </form>

  </div>

  <div class="container mt-5 mt-sm-0" *ngIf="!gameStarted.campaign">
    <div class="alert alert-warning text-center">
      <h4 class="alert-heading">Campaña No Iniciada</h4>
      <p>Aún no se ha iniciado la campaña. Por favor, espera a que tu profesor la inicie.</p>
    </div>
  </div>
</section>

<section class="bg-half-170 bg-light d-table w-100" *ngIf="gameStarted.userData.rol == 'teacher'">
  <div *ngIf="gameStarted.campaign">
    <div class="col-md-12"  *ngIf="gameStarted.campaign.is_travel_past">
      <div class="row">
        <div class="col-md-4" *ngFor="let milestone of milestones" (click)="gameStarted.campaign.current_milestone === milestone._id  ? startMilestone(modalBook, milestone) : null">
          <div class="storycard" [ngClass]="{
              'disabled': gameStarted.campaign.current_milestone !== milestone._id,
              'loadingMilestone': isLoadingMilestones && gameStarted.campaign.current_milestone === milestone._id
            }">
            <div class="spinner-container" *ngIf="isLoadingMilestones && gameStarted.campaign.current_milestone === milestone._id">
              <div class="spinner"></div>
            </div>
            <div class="storycard-content">
                <div class="storycard-image" style="background-image: url('assets/images/story/milestone/independency-chile/milestone-{{ milestone.chapter }}.jpg');"></div>
                <div class="storycard-title">{{ milestone.name }}</div>
                <div class="storycard-text">
                  - {{ getMilestoneText(milestone._id) }} -
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-5 mt-sm-0"
      *ngIf="!gameStarted.campaign.is_travel_past && gameStarted.campaign.team && gameStarted.campaign.team.length > 0; else noTeams">
      <div class="row">
        <div *ngFor="let team of gameStarted.campaign.team" class="col-lg-6 col-md-12 mb-4">
          <div class="card">
            <div class="card-header">
              <h5 class="mb-0">{{ team.acthor.name }}</h5>
            </div>
            <div class="card-body">
              <h6>Equipo:</h6>
              <ul class="list-group">
                <li *ngFor="let user of team.users" class="list-group-item">
                  {{ user.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-warning text-center mt-5" (click)="!isLoadingGroups? getStartedGame(): null">
        <div class="click-refresh-team">
          <i-feather class="refresh-icon " [ngClass]="{ 'loading': isLoadingGroups }" name="refresh-cw" ></i-feather>
        </div>
      </div>

      <div class="container mt-5 mt-sm-0">
        <div class="row mt-4" >
          <button class="btn btn-primary btn-lg" (click)="travelToPast()">¡Transportar al pasado!</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noTeams>
    <div class="alert alert-warning text-center mt-5">
      <h4>No se han creado equipos aún.</h4>
      <p class="mb-0">¡Espera a que se creen los grupos para liderar el camino!</p>
      <div class="click-refresh">
        <i-feather class="refresh-icon " [ngClass]="{ 'loading': isLoadingGroups }" name="refresh-cw" (click)="!isLoadingGroups? getStartedGame(): null"></i-feather>
      </div>
    </div>
  </ng-template>

  <div class="container mt-5 mt-sm-0"  *ngIf="!gameStarted.campaign">
    <div class="row" >
    </div>

    <div class="container mt-5 mt-sm-0">
      <div class="row mt-4" >
        <button class="btn btn-primary btn-lg" (click)="startCampaign()">Iniciar Campaña</button>
      </div>
    </div>
  </div>

</section>

<ng-template #modalBook let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ story.title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <label >Capitulo {{ story.chapter }}</label>
        <div class="input-group" [innerHTML]="story.content"></div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Cerrar</button>
    <button type="button" class="btn btn-primary" disabled>Proximamente Continuar Historia</button>
  </div>
</ng-template>





<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>

</div>

<app-options 
    class="centered"
    *ngIf="story && gameStarted.userData.rol === 'student'"
    [evaluationResults]="evaluationResults"
    [isEvaluationDone]="isEvaluationDone"
    [storyText]="story"
    (createTestEmmit)="createTest()"
    (finishEvaluationEmmit)="closeBook()"></app-options>
  
<app-evaluation
    class="app-evaluation"
    *ngIf="showEvaluation"
    [questionsEvaluation]="questionsEvaluation"
    [storyText]="story"
    (evaluationDoneEmmit)="evaluationDone($event)"></app-evaluation>

